$(document).ready(function () {
    $('.regions .region .region-content').hide();
    $('.regions .region').click(function () {
        if($(this).hasClass('active')) {
            $(this).removeClass("active").find(".region-content").slideUp(600);
            $(this).find('span').removeClass('opened');
        } else {
            $(this).next('.region-content').slideDown(600);
            $(this).removeClass('active');
            $(this).addClass("active").find(".region-content").slideDown(600);
            $(this).find('span').addClass('opened');
        }
        return false;
    })
});